<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card no-body>
      <div class="card-header">
        <!-- Title & SubTitle -->
        <div>
          <b-card-title>{{ options.title }}</b-card-title>
          <b-card-sub-title>{{ options.subTitle }}</b-card-sub-title>
        </div>

        <!-- Card Actions -->
        <div class="heading-elements">
          <ul class="list-inline mb-0">
            <li>
              <div class="d-flex align-items-center">
                <feather-icon icon="CalendarIcon" size="16" />
                <flat-pickr
                  v-model="rangePicker"
                  :config="{ mode: 'range' }"
                  class="form-control flat-picker bg-transparent border-0 shadow-none"
                  placeholder="YYYY-MM-DD"
                  @on-change="onDateChange"
                />
              </div>
            </li>
            <li>
              <feather-icon
                icon="InfoIcon"
                :title="options.tooltipTitle"
                v-b-popover.hover.left="options.tooltipContent"
                size="20"
              />
            </li>
          </ul>
        </div>
      </div>

      <!-- Card Body  -->
      <b-card-body>
        <slot v-bind:filters="filters"></slot>
      </b-card-body>
    </b-card> 
  </b-overlay>  
</template>

<script>
var qs = require("qs");
import {
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
  BSpinner,
  BOverlay,
  BCardHeader,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VBPopover,
    BSpinner,
    BOverlay,
    BCardHeader,
    flatPickr,
  },

  props: {
    options: {
      type: Object,
      default: {
      },
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  directives: {
    "b-popover": VBPopover,
  },

  data: function () {
    return {
      rangePicker: ["2019-05-01", "2019-05-10"],
      filters: ""
    };
  },

  created() {},
  methods: {
    onDateChange(selectedDates, dateStr) {
      console.log(selectedDates);
      console.log(dateStr);
      this.filters = dateStr;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
